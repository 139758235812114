import timg1 from '../images/team/img-1.jpg'
import timg2 from '../images/team/img-2.jpg'
import timg3 from '../images/team/img-3.jpg'
import timg4 from '../images/team/img-4.jpg'


const Teams = [
    {
        Id: '1',
        tImg:timg1,
        name:'Morkal Akunda',
        slug:'Morkal-Akunda',
        title:'Designer',
    },
    {
        Id: '2',
        tImg:timg2,
        name:'Tiptop Jonathon',
        slug:'Tiptop-Jonathon',
        title:'Developer',
    },
    {
        Id: '3',
        tImg:timg3,
        name:'Aleon Dela',
        slug:'Aleon-Dela',
        title:'Programmer',
    },
    {
        Id: '4',
        tImg:timg4,
        name:'Beila kelar',
        slug:'Beila-kelar',
        title:'Designer',
    }
]

export default Teams;